import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "小さく始める Design System ~メドレー TechLunch~",
  "date": "2018-04-26T08:49:23.000Z",
  "slug": "entry/2018/04/26/174923",
  "tags": ["medley"],
  "hero": "./2018_04_26.png",
  "heroAlt": "design system"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の舘野です。`}</p>
    <p>{`先日、メドレーで定期開催している社内勉強会「TechLunch」にて、Design System について発表しました。医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」において、Design System を「小さく始める」手法で導入を進めているので、そのプロセスについて紹介させていただこうと思います。`}</p>
    <h1>{`Design System とは何か`}</h1>
    <p>{`Design System とは、Salesforce の`}<a parentName="p" {...{
        "href": "https://lightningdesignsystem.com"
      }}>{`Lightning Design System`}</a>{`や IBM の`}<a parentName="p" {...{
        "href": "https://carbondesignsystem.com/"
      }}>{`Carbon Design System`}</a>{`などが代表的な例として挙げられると思いますが、平たく言ってしまうとプロダクト独自の`}<a parentName="p" {...{
        "href": "https://getbootstrap.com/"
      }}>{`Bootstrap`}</a>{`となるものです。`}</p>
    <p>{`UI 開発の領域では、これまでスタイルガイドを作ることでデザイナーとエンジニア間の共通言語とし、プロダクトの UI の一貫性を保つように努めることが一般的かと思いますが、Design System ではスタイルガイドだけでなくデザインの原則や UI コンポーネントの CSS や JS なども含めてプロダクトのインターフェースに関わる全てを、1 つのプロダクトとする考え方です。`}</p>
    <p>{`Design System はスタイルガイドと明確にどこが違うのかについて言及しているウェブ上の記事の多くは、Nathan Curtis 氏の`}<a parentName="p" {...{
        "href": "https://medium.com/eightshapes-llc/a-design-system-isn-t-a-project-it-s-a-product-serving-products-74dcfffef935"
      }}>{`「A Design System isn’t a Project. It’s a Product, Serving Products.」`}</a>{`という言葉を引用して、その差異を示しています。`}</p>
    <p>{`スタイルガイドがプロダクトにおけるプロジェクト以上の存在ではないのに対して、Design System はプロダクトに対して UI のエコシステムを提供するプロダクトである、ということが Design System の基幹となる考え方だと思います。`}</p>
    <h1>{`ジョブメドレーにおける Design System`}</h1>
    <p>{`TechLunch で発表したスライドはこちら。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/4716e3f00a9e441da1ec4fdce5d1ff4a" title="小さく始める Design System  /Design System" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "314px"
    }} data-ratio="1.78343949044586"></iframe>
    <p>{`今回 Design System を段階的に導入しているのは、医療介護の求人サイト「ジョブメドレー」です。`}</p>
    <p>{`ジョブメドレーのインターフェースが今後より一層色々な形でユーザに使われる場面が増えていくことが想定される中で、プロダクトの UI の一貫性や生産性を担保し続けていくためには、スタイルガイドを作るだけでなく Design System によってより包括的にプロダクトの UI 開発に対する取り組み方を変える必要があるのではと考えていました。`}</p>
    <p>{`とはいえ最初からプロダクトの UI 全てを Design System に置き換えるというのは変化が大き過ぎるし、Design System がうまく機能せずに失敗する場合も考慮しておく必要があったので、導入がうまくいかないければすぐにやめられるように以下の点を導入前に決めていました。`}</p>
    <ul>
      <li parentName="ul">{`最初から一気に色々やらない`}</li>
      <li parentName="ul">{`まずは一部だけ導入してみる`}</li>
      <li parentName="ul">{`上手くいく部分といかない部分を検証する`}</li>
      <li parentName="ul">{`Design System の改善と段階導入を繰り返す`}</li>
    </ul>
    <p>{`Design System はプロダクトに UI のエコシステムを提供するプロダクトなので、一定期間で作って終わりではなく継続して改善を繰り返していくという点では、このような進め方が適切と考えました。`}</p>
    <p>{`実際のところ、現在のジョブメドレーでは一部分だけジョブメドレーの Design System として npm 化したモジュールから提供するようにしています。`}</p>
    <p>{`npm 化して Design System から提供するようにしたのは、以下の 3 つのみです。`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.npmjs.com/package/jmds-tools"
          }}>{`jmds-tools`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`sass mixin や function を提供するユーティリティモジュール`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.npmjs.com/package/jmds-tokens"
          }}>{`jmds-tokens`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`design tokens（デザイン上の値を信頼できる唯一の情報源として 1 つの場所で定義されるもの）`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.npmjs.com/package/jmds-flex"
          }}>{`jmds-flex`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`flexbox ユーティリティ`}</li>
        </ul>
      </li>
    </ul>
    <p>{`プロダクトの UI として見ると、flexbox のユーティリティクラスを Design system から提供するようになっただけです。`}</p>
    <p>{`ただ、今は全体のごく一部が Design System から提供されていますが、Design System に移行できる UI コンポーネントを選定して段階的に npm 化をしていくことで、プロダクトの UI コンポーネントの多くが Design System から提供されている状態にすることが可能だと思います。`}</p>
    <p>{`単純に npm 化することが目的ではなく、npm 化した Design System をプロダクトチームでメンテナンスしていくことで、より一貫性のある UI をジョブメドレーのプロダクトに提供していくことが目的です。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回は、TechLunch で発表したジョブメドレーにおける Design System の取り組みについて紹介させていただきました。`}</p>
    <p>{`今回紹介した Design System が今後デザイナー、エンジニア、プロダクトマネージャーと協力しながら、ジョブメドレーのプロダクトの UI を支える強固な土台へと成長させられるように取り組んでいこうと思います。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、医療介護の求人サイト「ジョブメドレー」の他にも、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」、口コミで探せる介護施設の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」、オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p>{`ちょっと興味があるという方も、ぜひお気軽にご連絡ください！`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      